import { startTransition, StrictMode, useEffect } from "react"
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react"
import * as Sentry from "@sentry/remix"
import { hydrateRoot } from "react-dom/client"

// Initialize Sentry for error tracking and performance monitoring
const initSentry = () => {
	Sentry.init({
		dsn: "https://be6cb1ffa1532e8f22e3725e78971a7b@o4508050318819328.ingest.us.sentry.io/4508053803171840",
		tracesSampleRate: 1,
		integrations: [
			Sentry.browserTracingIntegration({
				useEffect,
				useLocation,
				useMatches,
			}),
			// no idea what this eslint error means
			// eslint-disable-next-line import/namespace
			Sentry.replayIntegration({
				maskAllText: true,
				blockAllMedia: true,
			}),
		],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1,
	})
}

// Hydrate the React app
const hydrateApp = () => {
	hydrateRoot(
		document,
		<StrictMode>
			<RemixBrowser />
		</StrictMode>,
	)
}

// Main initialization function
const initApp = async () => {
	initSentry()
	hydrateApp()
}

// Use startTransition to prioritize UI responsiveness
startTransition(() => {
	initApp()
})
